
    //import { VueEcharts } from 'vue3-echarts';
    import * as echarts from "echarts";
    import {getAnalyze} from '@/network/ship';
    import {defineComponent, ref, onMounted, reactive, onUnmounted} from 'vue';

    type EChartsOption = echarts.EChartsOption;
    export default defineComponent({
        name: "CommonAnalyze",
        setup() {
            const uid = window.localStorage.getItem('uid');
            const role = ref<number>(0);
            const efficiency = ref<number>(0);
            const percent = ref<number>(0);
            //const chart = ref(null);
            let echart = echarts;
            let option: EChartsOption = {};
            const analyze = async () => {
                await getAnalyze({'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        console.log(response);
                        role.value = response.role;
                        efficiency.value = (parseInt(response.all.allUseTime) / 60) / response.all.allGd;
                        //console.log(efficiency.value);
                        if (role.value == 1) {
                            percent.value = response.all.allGd / response.allGd;
                        }
                        option = {
                            title: {
                                text: '工单处理状态占比',
                                subtext: '完成、超时完成与未完成',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: [
                                        {
                                            value: response.percent[0].undone,
                                            name: '未完成'
                                        }, {
                                            value: response.percent[1].overtime,
                                            name: '超时完成'
                                        }, {value: response.percent[2].complete, name: '已完成'}
                                    ],
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                    } else {
                        console.log(response.msg);
                    }
                });
            }

            function initChart() {
                let chart = echart.init(document.getElementById("myEcharts")!);
                // 把配置和数据放这里
                chart.setOption(option);
                window.onresize = function () {
                    //自适应大小
                    chart.resize();
                };
            }

            onMounted(async () => {
                await analyze();
                initChart();
            });
            onUnmounted(() => {
                echart.dispose;
            });
            return {
                //option,
                //option1,
                role,
                efficiency,
                percent,
                initChart,
            }
        },
        components: {},
    })
